function setCookie(cname, value, expiryMinutes) {
	let currentDate = new Date();
	currentDate.setTime(currentDate.getTime() + expiryMinutes * 60 * 1000);
	var expires = "expires=" + currentDate.toUTCString();
	document.cookie = cname + "=" + value + ";" + expires + ";path=/";
}

function getCookie(name) {
	let value = `; ${document.cookie}`;
	let parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts.pop().split(";").shift();
	}

	return null;
}

function getCurrentUTCTimestamp() {
	let currentDate = new Date();
	let currentUTCDate = new Date(
		currentDate.getUTCFullYear(),
		currentDate.getUTCMonth(),
		currentDate.getUTCDate(),
		currentDate.getUTCHours(),
		currentDate.getUTCMinutes(),
		currentDate.getUTCSeconds()
	);

	return Math.floor(currentUTCDate.getTime() / 1000);
}

function escapeWebsite() {
	let cookieValue = getCookie("ACCESS-SAFE");
	console.log(cookieValue);
	if (cookieValue === null) {
		setCookie("ACCESS-SAFE", 1, 15);
	}

	$("body").hide();
	$("title").text("Loading google.com...");

	window.open("http://www.google.com/", "_newtab");
	window.location.replace("/safe.cfm");
}

function checkCookie() {
	let cookieValue = getCookie("ACCESS-SAFE");

	if (cookieValue === null) {
		return;
	}

	escapeWebsite();
}

jQuery(document).ready(function($) {
	$("#escapeWebsite").on("click", escapeWebsite);

	// Bind an event handler for the escape key
	$(document).keyup(function(objEvent) {
		if (objEvent.keyCode == 27) {
			escapeWebsite();
		}
	});
});

checkCookie();
